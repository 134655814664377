import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { initializeSegment, pageView } from './helpers/segment';

//import Scss
import './assets/scss/themes.scss';

//imoprt Route
import Route from './Routes';

// Import Firebase Configuration file
// import { initFirebaseBackend } from "./helpers/firebase_helper";

function App() {
  const location = useLocation();

  useEffect(() => {
    initializeSegment();
  }, []);

  useEffect(() => {
    pageView(location.pathname);
  }, [location]);
  
  return (
    <React.Fragment>
      <Route />
    </React.Fragment>
  );
}

export default App;