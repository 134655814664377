import { AnalyticsBrowser } from '@segment/analytics-next';

let analytics = null;

export const initializeSegment = async () => {
  if (!analytics) {
    const writeKey = process.env.REACT_APP_SEGMENT_WRITE_KEY;
    analytics = AnalyticsBrowser.load({ writeKey });
  }
};

export const trackEvent = (eventName, properties = {}) => {
  if (!analytics) return;
  analytics.track(eventName, properties);
};

export const identifyUser = (userId, traits = {}) => {
  if (!analytics) return;
  analytics.identify(userId, traits);
};

export const pageView = (url) => {
  if (!analytics) return;
  analytics.page(url);
};