import PropTypes from "prop-types";
import React, { useEffect } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
} from "reactstrap";
import { Link } from "react-router-dom";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";

import withRouter from "../../Components/Common/withRouter";
import logoSvg from "../../assets/images/logo.svg";
import { useSignOut } from "react-firebase-hooks/auth";
import { getAuth } from "firebase/auth";
import { setAuthorization } from "../../helpers/api_helper";
import { setFirebaseUser } from "../../store/token/actions";

const Logout = (props) => {

  const auth = getAuth();
  const [signOut, loading, error] = useSignOut(auth);

  useEffect(() => {
    signOut().then(() => {
      console.log("Sign out successful");
    }).catch((error) => { 
      console.log("Sign out error: ", error);
    });    
    setFirebaseUser({});
    setAuthorization(null);
  }, []);

  // useEffect(() => {
  //   if (state.user.values?.quickonde_id) {
  //     store.dispatch(setQuickNodeUser(undefined));
  //   } else {
  //     store.dispatch(setFirebaseUser(undefined));
  //   }
  // }, []);

  document.title = "Log Out | CoinAPI.io Login";

  return (
    <React.Fragment>
      <div className="auth-page-content">
        <div className="auth-page-wrapper">
          <ParticlesAuth>
            <div className="auth-page-content">
              <Container>
                <Row>
                  <Col lg={12}>
                    <div className="text-center mt-sm-5 mb-4 text-white-50">
                      <div>
                        <Link
                          to="https://www.coinapi.io/"
                          className="d-inline-block auth-logo"
                        >
                          <img src={logoSvg} alt="" height="40" />
                        </Link>
                      </div>
                    </div>
                  </Col>
                </Row>

                <Row className="justify-content-center">
                  <Col md={8} lg={6} xl={5}>
                    <Card className="mt-4">
                      <CardBody className="p-4 text-center">
                        <lord-icon
                          src="https://cdn.lordicon.com/hzomhqxz.json"
                          trigger="loop"
                          colors="primary:#405189,secondary:#08a88a"
                          style={{ width: "180px", height: "180px" }}
                        ></lord-icon>

                        <div className="mt-4 pt-2">
                          <h5>You are Logged Out</h5>
                          <p className="text-muted">
                            Thank you for using{" "}
                            <span className="fw-semibold">CoinAPI.io</span>
                          </p>
                          {/* <div className="mt-4">
                            <Link to="/" className="btn btn-success w-100">
                              Sign In
                            </Link>
                          </div> */}
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </div>
          </ParticlesAuth>
        </div>
      </div>
    </React.Fragment>
  );
};

Logout.propTypes = {
  history: PropTypes.object,
};

export default withRouter(Logout);
