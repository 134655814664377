import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import withRouter from "../../Components/Common/withRouter";
import { loginQuickNode } from "../../helpers/firebase_helper";

const QuickLogin = (props) => {
  const dispatch1 = useDispatch();
  // const { token } = useParams();
  const {search} = useLocation()
  const token = search.split("?")[1]
  loginQuickNode(token)

  useEffect(() => {
    if (token) {
      setTimeout(() => {
        props.router.navigate("/");
      }, 1000);
    }
  }, [token]);

  return (
    <div>
      {/* <h1>Decoded JWT Token:</h1> */}
      {/* <pre>{JSON.stringify(decodedToken, null, 2)}</pre> */}
    </div>
  );
};

export default withRouter(QuickLogin);
