import React, { useEffect } from "react";
import { Navigate, Route } from "react-router-dom";
import { useDispatch } from "react-redux";

const AuthProtected = (props) => {
  const dispatch = useDispatch();

  /*
    Navigate is un-auth access protected routes via url
    */
  // if (!userProfile) {
  //   return (
  //     <Navigate to={{ pathname: "/", state: { from: props.location } }} />
  //   );
  // }

  return <>{props.children}</>;
};

const AccessRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          <>
            {" "}
            <Component {...props} />{" "}
          </>
        );
      }}
    />
  );
};

export { AuthProtected, AccessRoute };
