import firebase from "firebase/compat/app";

// Add the Firebase products that you want to use
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import Cookies from 'js-cookie';

// Nasze importy
import {
  AuthStateChangedStatus,
  setChangeAuthStateChangedStatus,
  setFirebaseUser,
  setQuickNodeUser,
  setRedirect,
} from "../store/token/actions";
import store from "../store/token/store";
import config from "../config";

class FirebaseAuthBackend {
  firebaseConfig;

  constructor(firebaseConfig) {
    this.firebaseConfig = firebaseConfig;
    this.initialize();
  }

  async initialize() {
    if (this.firebaseConfig) {
      // Initialize Firebase
      firebase.initializeApp(this.firebaseConfig);
      
      // await handleChange();
    }
  }

  async handleChange() {
    return new Promise((resolve, reject) => {
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          store.dispatch(setFirebaseUser(user));
          const returnSSO = store.getState().redirect?.returnSSO;
          const returnTo = store.getState().redirect?.returnTo;

          var url = (store.getState().redirect !== null) ? returnSSO || returnTo : config.defaultSite;
          store.dispatch(setRedirect(null));
          user.getIdToken().then((token) => {                              
            if (returnTo && returnSSO) {
              url += `?token=${token}&return_to=${returnTo}`;
            }
            else {
              url += `?token=${token}`;
            }
            window.open(url, '_self');
          });              
        }
        store.dispatch(
          setChangeAuthStateChangedStatus(AuthStateChangedStatus.Finish)
        );
        resolve();
      });
    });
  }

  /**
   * Registers the user with given details
   */
  registerUser = (email, password) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then(
          (user) => {
            resolve(firebase.auth().currentUser);
          },
          (error) => {
            reject(this._handleError(error));
          }
        );
    });
  };

  /**
   * Registers the user with given details
   */
  editProfileAPI = (email, password) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then(
          (user) => {
            resolve(firebase.auth().currentUser);
          },
          (error) => {
            reject(this._handleError(error));
          }
        );
    });
  };

  /**
   * Login user with given details
   */
  // loginUser = (email, password) => {
  loginUser = (email) => {
    return new Promise((resolve, reject) => {
      var actionCodeSettings = {
        // URL you want to redirect back to. The domain (www.example.com) for this URL
        // must be whitelisted in the Firebase Console.
        url: window.location.protocol + "//" + window.location.host + "/", // Here we redirect back to this same page.
        handleCodeInApp: true, // This must be true.
      };

      firebase
        .auth()
        .sendSignInLinkToEmail(email, actionCodeSettings)
        // .signInWithEmailLink(email)
        // .signInWithEmailAndPassword(email, password)
        .then(
          (user) => {            
            resolve(firebase.auth().currentUser);
          },
          (error) => {
            reject(this._handleError(error));
          }
        );
    });
  };

  /**
   * forget Password user with given details
   */
  forgetPassword = (email) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .sendPasswordResetEmail(email, {
          url:
            window.location.protocol + "//" + window.location.host + "/",
        })
        .then(() => {
          resolve(true);
        })
        .catch((error) => {
          reject(this._handleError(error));
        });
    });
  };

  /**
   * Logout the user
   */
  logout = () => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signOut()
        .then(() => {
          resolve(true);
        })
        .catch((error) => {
          reject(this._handleError(error));
        });
    });
  };

  /**
   * Social Login user with given details
   */
  socialLoginUser = (data, type) => {
    let credential = {};
    if (type === "google") {
      credential = firebase.auth.GoogleAuthProvider.credential(
        data.idToken,
        data.token
      );
    } else if (type === "facebook") {
      credential = firebase.auth.FacebookAuthProvider.credential(data.token);
    }
    return new Promise((resolve, reject) => {
      if (!credential) {
        firebase
          .auth()
          .signInWithCredential(credential)
          .then((user) => {
            resolve(this.addNewUserToFirestore(user));
          })
          .catch((error) => {
            reject(this._handleError(error));
          });
      } else {
        // reject(this._handleError(error));
      }
    });
  };

  addNewUserToFirestore = (user) => {
    const collection = firebase.firestore().collection("users");
    const { profile } = user.additionalUserInfo;
    const details = {
      firstName: profile.given_name ? profile.given_name : profile.first_name,
      lastName: profile.family_name ? profile.family_name : profile.last_name,
      fullName: profile.name,
      email: profile.email,
      picture: profile.picture,
      createdDtm: firebase.firestore.FieldValue.serverTimestamp(),
      lastLoginTime: firebase.firestore.FieldValue.serverTimestamp(),
    };
    collection.doc(firebase.auth().currentUser.uid).set(details);
    return { user, details };
  };

  /**
   * Handle the error
   * @param {*} error
   */
  _handleError(error) {
    // var errorCode = error.code;
    var errorMessage = error.message;
    return errorMessage;
  }
}

let _fireBaseBackend = null;

/**
 * Initilize the backend
 * @param {*} config
 */
const initFirebaseBackend = (config) => {
  if (!_fireBaseBackend) {
    _fireBaseBackend = new FirebaseAuthBackend(config);
  }
  return _fireBaseBackend;
};

/**
 * Returns the firebase backend
 */
const getFirebaseBackend = () => {
  return _fireBaseBackend;
};

const loginQuickNode = (token) => {
  const auth = getAuth();
  signInWithCustomToken(auth, token.split("=")[1])
    .then((userCredential) => {
      // Signed in
      const user = userCredential.user;
      store.dispatch(setQuickNodeUser(user))
      // ...
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      // console.log('error', errorCode, ":", errorMessage);
      // ...
    });
};

const getFirebaseUser = () => {
  return new Promise((resolve) => {
    const state1 = store.getState();
    if (state1.authStateChanged === AuthStateChangedStatus.Finish) {
      resolve(state1.user.values);
    } else {
      const unsubscribe = store.subscribe(() => {
        const state = store.getState();
        if (state.authStateChanged === AuthStateChangedStatus.Finish) {
          unsubscribe();
          resolve(state.user.values);
        }
      });
    }
  });
};

export { initFirebaseBackend, getFirebaseBackend, getFirebaseUser, loginQuickNode };
