export const SET_FIREBASE_USER = "SET_FIREBASE_USER";
export const AUTH_STATE_CHANGED = "AUTH_STATE_CHANGED";
export const SET_QUICKNODE_USER = "SET_QUICKNODE_USER";
export const SET_BILLING_EXTERNAL = "SET_BILLING_EXTERNAL";
export const SET_REDIRECT = "SET_REDIRECT";
export const AuthStateChangedStatus = {
    Before: 0,
    Finish: 1
}

export const setFirebaseUser = (values) => ({
  type: SET_FIREBASE_USER,
  payload: { values }
});

export const setBillingExternal = (values) => ({
  type: SET_BILLING_EXTERNAL,
  payload: { values }
});
  

export const setQuickNodeUser = (values) => ({
  type: SET_QUICKNODE_USER,
  payload: { values }
})

export const setChangeAuthStateChangedStatus = value => ({
  type: AUTH_STATE_CHANGED,
  payload: value,
});

export const setRedirect = value => ({
  type: SET_REDIRECT,
  payload: value,
});