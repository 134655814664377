import React from "react";

//login
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import LoginEmail from "../pages/Authentication/LoginEmail";
import QuickLogin from "../pages/Authentication/QuickLogin";
import Basic404 from "../pages/AuthenticationInner/Errors/Basic404";

const authProtectedRoutes = [
];

const publicRoutes = [
  // Authentication Page
  { path: "/logout", component: <Logout /> },
  { path: "/", component: <Login /> },
  { path: "/loginLink", component: <LoginEmail /> },
  { path: "/login/quicknode", component: <QuickLogin /> },
  // { path: "/loginTwoStep", component: <BasicTwosVerify />},
  { path: "*", component: <Basic404 /> },
];

export { authProtectedRoutes, publicRoutes };
