import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
} from "reactstrap";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import { Link } from "react-router-dom";

import withRouter from "../../Components/Common/withRouter";
import logoSvg from "../../assets/images/logo.svg";
import { useSignInWithEmailLink } from "react-firebase-hooks/auth";
import { getAuth } from "firebase/auth";
import { getFirebaseBackend, initFirebaseBackend } from "../../helpers/firebase_helper";
import { useSearchParams } from "react-router-dom";
import { setRedirect } from "../../store/token/actions";
import store from "../../store/token/store";

const Logout = (props) => {  

  const [urlParams] = useSearchParams();
  // const redirect = urlParams.get('redirect');
  const returnTo = localStorage.getItem("returnTo");
  const returnSSO = localStorage.getItem("returnSSO");
  if (returnTo && returnSSO) {
    store.dispatch(setRedirect({returnSSO, returnTo}));
  }

  const [message, setMessage] = useState("Link validation is in progress ...");
  const [errorMessage, setErrorMessage] = useState(null);

  const auth = getAuth();
  const [signInWithEmailLink, user, loading, error] =
    useSignInWithEmailLink(auth);

  useEffect(() => {
    const email = localStorage.getItem("emailLink");
    if (!email) {
      setErrorMessage("A login request was not sent from this browser.");
      return;
    }
    signInWithEmailLink(email);
  }, []);

  useEffect(() => {
    if (user) {
      setErrorMessage(null);
      setMessage("You have been logged in.");
      getFirebaseBackend().handleChange();
    }
  }, [user]);

  useEffect(() => {
    if (error) {
      setErrorMessage(error.message);
    }
  }, [error]);

  document.title = "SignIn | CoinAPI.io Login";

  return (
    <React.Fragment>
      <div className="auth-page-content">
        <div className="auth-page-wrapper">
          <ParticlesAuth>
            <div className="auth-page-content">
              <Container>
                <Row>
                  <Col lg={12}>
                    <div className="text-center mt-sm-5 mb-4 text-white-50">
                      <div>
                        <Link
                          to="https://www.coinapi.io/"
                          className="d-inline-block auth-logo"
                        >
                          <img src={logoSvg} alt="" height="40" />
                        </Link>
                      </div>                      
                    </div>
                  </Col>
                </Row>

                <Row className="justify-content-center">
                  <Col md={8} lg={6} xl={5}>
                    <Card className="mt-4">
                      <CardBody className="p-4 text-center">
                        <lord-icon
                          src="https://cdn.lordicon.com/hzomhqxz.json"
                          trigger="loop"
                          colors="primary:#405189,secondary:#08a88a"
                          style={{ width: "180px", height: "180px" }}
                        ></lord-icon>

                        <div className="mt-4 pt-2">
                          <h5>Link verification</h5>
                          {errorMessage ? (
                            <>
                              <p className="" style={{ color: "red" }}>
                                {errorMessage}
                              </p>
                              <div className="mt-4">
                                <Link
                                  to="/"
                                  className="btn btn-success w-100"
                                >
                                  Sign In
                                </Link>
                              </div>
                            </>
                          ) : (
                            <p className="text-muted">{message}</p>
                          )}
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </div>
          </ParticlesAuth>
        </div>
      </div>
    </React.Fragment>
  );
};

Logout.propTypes = {
  history: PropTypes.object,
};

export default withRouter(Logout);
