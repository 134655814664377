import { combineReducers } from "redux";

// Authentication
import Login from "./auth/login/reducer";
import Firebase from "./token/reducer";

const rootReducer = combineReducers({
  // public
  Login,
  Firebase,
});

export default rootReducer;
