import React from 'react';
import { createRoot } from "react-dom/client";
import App from './App';
import reportWebVitals from './reportWebVitals';

import { BrowserRouter, useLocation, useNavigate } from "react-router-dom";
import { Provider } from "react-redux";

import { configureStore } from "./store";
import { initFirebaseBackend } from './helpers/firebase_helper';
import config from './config';

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

initFirebaseBackend(config.firebaseConfig);

root.render(
    <Provider store={configureStore({})}>
      <React.Fragment>
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <App />
        </BrowserRouter>
      </React.Fragment>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();