import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Button,
  Form,
  FormFeedback,
  Alert,
} from "reactstrap";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import { Link } from "react-router-dom";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

import logoSvg from "../../assets/images/logo.svg";
import withRouter from "../../Components/Common/withRouter";

import { getAuth } from "firebase/auth";
import {
  useSignInWithGithub,
  useSignInWithGoogle,
  useSendSignInLinkToEmail,
} from "react-firebase-hooks/auth";
import { getFirebaseBackend, initFirebaseBackend } from "../../helpers/firebase_helper";
import config from "../../config";
import { useSearchParams } from "react-router-dom";
import { setRedirect } from "../../store/token/actions";
import store from "../../store/token/store";

const Login = (props) => {

  const [urlParams] = useSearchParams();
  const returnTo = urlParams.get('return_to');
  const returnSSO = urlParams.get('return_sso');  

  const redirect = returnSSO || returnTo ? `${returnSSO}?return_to=${returnTo}` : null;  
  
  // const [userLogin, setUserLogin] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [loginMessage, setLoginMessage] = useState(null);

  const auth = getAuth();

  const [signInWithGithub, userGithub, loadingGithub, errorGithub] =
    useSignInWithGithub(auth);
  const [signInWithGoogle, userGoogle, loadingGoogle, errorGoogle] =
    useSignInWithGoogle(auth);
  const [sendSignInLinkToEmail, sending, errorSendLink] =
    useSendSignInLinkToEmail(auth);

  const actionCodeSettings = {
    url: window.location.protocol + "//" + window.location.host + "/loginLink" + (redirect ? "?redirect=" + redirect : ""),
    handleCodeInApp: true,
  };  

  if (redirect) {
    localStorage.setItem("returnSSO", returnSSO);
    localStorage.setItem("returnTo", returnTo);
    store.dispatch(setRedirect({ returnSSO, returnTo }));
  }

  useEffect(() => {
    getFirebaseBackend().handleChange();
  }, []);

  useEffect(() => {
    const errorMessage =
      (errorGithub && errorGithub.code) ||
      (errorGoogle && errorGoogle.code) ||
      (errorSendLink && errorSendLink.code) ||
      null;
    setErrorMessage(errorMessage);
  }, [errorGithub, errorGoogle, errorSendLink]);

  useEffect(() => {
    if (userGithub || userGoogle) {
      getFirebaseBackend().handleChange();      
    }
  }, [userGithub, userGoogle]);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: false,

    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
    }),
    onSubmit: (values) => {
      const success = sendSignInLinkToEmail(values.email, actionCodeSettings);
      if (success) {
        localStorage.setItem("emailLink", values.email);
        setLoginMessage("Please check your email.");
      }
    },
  });

  document.title = "SignIn | CoinAPI.io Login";

  return (
    <React.Fragment>
      <ParticlesAuth>
        <div className="auth-page-content">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center mt-sm-5 mb-4 text-white-50">
                  <div>
                    <Link
                      to="https://www.coinapi.io/"
                      className="d-inline-block auth-logo"
                    >
                      <img src={logoSvg} alt="" height="40" />
                    </Link>
                  </div>                  
                </div>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="mt-4">
                  <CardBody className="p-4">
                    <div className="text-center mt-2">
                      <h5 className="text-primary">Welcome Back !</h5>
                      <p className="text-muted">
                        Sign in to continue to CoinAPI.io
                      </p>
                    </div>
                    {loginMessage && loginMessage ? (
                      <Alert color="info"> {loginMessage} </Alert>
                    ) : null}
                    {errorMessage && errorMessage ? (
                      <Alert color="danger"> {errorMessage} </Alert>
                    ) : null}
                    <div className="p-2 mt-4">
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                        action="#"
                      >
                        <div className="mb-3">
                          <Label htmlFor="email" className="form-label">
                            Email
                          </Label>
                          <Input
                            name="email"
                            className="form-control"
                            placeholder="Enter email"
                            type="email"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={
                              validation.touched.email &&
                              validation.errors.email
                                ? true
                                : false
                            }
                          />
                          {validation.touched.email &&
                          validation.errors.email ? (
                            <FormFeedback type="invalid">
                              {validation.errors.email}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mt-4">
                          <Button
                            color="success"
                            className="btn btn-success w-100"
                            type="submit"
                          >
                            Sign In
                          </Button>
                        </div>

                        <div className="mt-4 text-center">
                          <div className="signin-other-title">
                            <h5 className="fs-13 mb-4 title">Sign In with</h5>
                          </div>
                          <div>
                            {/* <Button onClick={() => signInWithEmailLink('tom.przybysz@gmail.com')} color="dark" className="btn-icon"><i className="ri-email-fill fs-16"></i></Button> */}
                            <Button
                              onClick={() => signInWithGithub()}
                              color="dark"
                              to="#"
                              className="btn-icon"
                              style={{ marginRight: "5px" }}
                            >
                              <i className="ri-github-fill fs-16"></i>
                            </Button>
                            <Button
                              onClick={() => signInWithGoogle()}
                              color="danger"
                              to="#"
                              className="btn-icon me-1"
                            >
                              <i className="ri-google-fill fs-16" />
                            </Button>
                          </div>
                        </div>
                      </Form>
                    </div>
                  </CardBody>
                </Card>

                <div className="mt-4 text-center">
                  <p className="mb-0">
                    <b>Not a member yet?</b>
                    <br />
                    Your new account will be set up automatically with your
                    first login if you are our customer.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </ParticlesAuth>
    </React.Fragment>
  );
};

export default withRouter(Login);
