import { redirect } from 'react-router-dom';
import { SET_FIREBASE_USER, AUTH_STATE_CHANGED, AuthStateChangedStatus, SET_QUICKNODE_USER, SET_BILLING_EXTERNAL, SET_REDIRECT } from './actions';

const initialState = {  
  user: {},
  authStateChanged: AuthStateChangedStatus.Before,
  redirect: null,
};

export default function firebaseReducer(state = initialState, action) {
  switch(action.type) {
    case SET_BILLING_EXTERNAL:
      return {
        ...state,
        billing_external: action.payload,
      };
    case SET_FIREBASE_USER:
      return {
        ...state,
        user: action.payload,
      };
    case SET_QUICKNODE_USER:
      return {
        ...state,
        user: action.payload,
      };
    case AUTH_STATE_CHANGED: 
      return {
        ...state,
        authStateChanged: action.payload,
      }  
    case SET_REDIRECT:
      return {
        ...state,
        redirect: action.payload,
      };
    default:
      return state;
  }
}